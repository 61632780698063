import React, {Component} from "react";
import styles from "./autocomplete.module.css";

class AddressLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: null
        };
    }

    componentDidMount() {
        this.renderAutoComplete();
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps.map) this.renderAutoComplete();
    }

    onSubmit(e) {
        e.preventDefault();
    }

    clickHandler() {
    }

    renderAutoComplete() {
        const {google} = this.props;
        if (!google) return;

        const autocomplete = new google.maps.places.Autocomplete(this.autocomplete);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();

            if (!place.geometry) return;

            this.setState({position: place.geometry.location});
            this.props.clickHandler({position: place.geometry.location});
        });
    }

    render() {
        return (
            <div className={styles.flexWrapper}>
                <div className={styles.left}>
                    <form onSubmit={this.onSubmit}>
                        <div>
                            <input
                                placeholder="Enter a location"
                                ref={ref => (this.autocomplete = ref)}
                                type="text"
                                size={40}
                                className="address-input"
                            />
                            <input className={styles.button} type="submit" value="Go"/>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default AddressLine;
