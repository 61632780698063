import React, {PureComponent} from 'react';
import { terra } from "terra-api-ts/service.pb";

export interface InfoContainerProps {
    selectedPoint: terra.IGridPoint
}

export class InfoContainer extends PureComponent<InfoContainerProps, any> {
    render() {
        const pnt = this.props.selectedPoint
        return <div className="InfoContainer">
            <div style={{fontSize: 'small'}}>Point: {pnt.id?.value}</div>
            <div style={{fontSize: 'small'}}>Dataset: {pnt.datasetId?.value}</div>
            <div style={{fontSize: 'small'}}>Dataset name: {pnt.datasetName}</div>
            <hr/>
            <div>Name: {pnt.externalName}</div>
            <div>External ID: {pnt.externalId}</div>
            <div>Lat: {pnt.position?.lat}, Lng: {pnt.position?.lng}</div>
            <hr/>
        </div>
    }
}
