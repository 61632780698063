import React, {PureComponent} from 'react';
import {terra} from "terra-api-ts/service.pb";
import { InfoContainerProps } from "./infoContainer";
import '../App.css';
import * as d3lib from 'd3'
import {colors} from "../colors";
import Dropdown, {Option} from 'react-dropdown';
import 'react-dropdown/style.css';

interface DataSeries {
    val: number
    timestamp: terra.IWeatherTimestamp
}

export interface HeatmapContainerProps extends InfoContainerProps {
    getTd: tdGetter
}

type tdGetter = () => terra.TerraData

export class Heatmap extends PureComponent<HeatmapContainerProps, any> {
    selectedOption = 'globalHorizIrrWm2'
    data: terra.IGridPointWithData | null
    getTd: tdGetter

    constructor(props: HeatmapContainerProps) {
        super(props);
        this.onChangedSelection = this.onChangedSelection.bind(this)
        this.data = null
        this.getTd = props.getTd
    }

    onChangedSelection(opt: Option) {
        this.selectedOption = opt.value
        if (this.data != null) {
            this.showGraph(this.data)
        }
    }

    componentDidUpdate() {
        // Get the point data
        const td = this.props.getTd()
        td.getPointData(terra.GetPointDataRequest.create({
            id: this.props.selectedPoint.id,
            includeFeb29: false,
            resolution: terra.TimeResolution.HOURLY,
        })).catch(err => {
            console.log("Error: " + err)
        }).then(res => {
            const data = (res as terra.IGridPointWithData)
            this.data = data
            this.showGraph(data)
        });
    }

    showGraph(data: terra.IGridPointWithData) {
        const series = new Array<DataSeries>()
        let min = 10 ^ 9, max = 0;
        data!.dataList!.forEach(w => {
            //const val = w.directNormalIrrWm2! as number
            const val = (w as any)[this.selectedOption] as number
            series.push({
                val: val,
                timestamp: w.timestamp!,
            })
            if (val > max) {
                max = val
            }
            if (val < min) {
                min = val
            }
        });
        const variance = max - min;

        //Setting chart width and adjusting for margins
        const width = 270,
            height = 366,
            margins = {top: 20, right: 5, bottom: 100, left: 5};

        // Remove the old chart
        d3lib.selectAll("svg > *").remove();

        const chart = d3lib.select('.chart')
            .attr('width', width + margins.right + margins.left)
            .attr('height', height + margins.top + margins.bottom)
            .append('g')
            .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

        const tooltip = d3lib.select('.tooltip')

        //Return dynamic color based on intervals in legendData
        // @ts-ignore
        const pal = colors()
        const colorScale = (d: any) => {
            const idx = Math.trunc((d.val - min) / (variance) * pal.length)
            return pal[idx];
        };

        let barWidth = 10, barHeight = 1
        //Append heatmap bars, styles, and mouse events
        chart.selectAll('g')
            .data(series).enter().append('g')
            .attr('transform', 'translate(5,0)')
            .append('rect')
            .attr('x', d => {
                return (d.timestamp.hour!) * barWidth
            })
            .attr('y', d => {
                return Math.trunc((d.timestamp.hourOfYear! / 24) * barHeight)
            })
            .style('fill', colorScale)
            .attr('width', barWidth)
            .attr('height', barHeight)
            .on('mouseover', d => {
                // tooltip.html(
                //     `${d.timestamp.month}/${d.timestamp.day} ${d.timestamp.hour}:00 = ${d.val}`)
                //     .style('left', (d3sel.event.pageX - 35) + 'px')
                //     .style('top', d3sel.event.pageY + 'px')
                //     .style('z-index', '0')
                //     .style('opacity', .9);
            }).on('mouseout', () => {
            tooltip.style('opacity', 0)
                .style('left', '0px');
        });

        //Append x axis
        const xScale = d3lib.scaleLinear()
            .range([0, 240])
            .domain([0, 23]);
        chart.append('g')
            .attr('transform', 'translate(5,' + height + ')')
            .call(d3lib.axisBottom(xScale)
                .ticks(24)
                .tickFormat(d3lib.format('.2'))
                .tickSize(4));

        // Append color legend using legendData
        chart.append('g')
            .selectAll('g')
            .data(pal).enter()
            .append('rect')
            .attr('width', 1)
            .attr('height', 20)
            .attr('x', (d, i) => {
                return i;
            })
            .attr('y', height + margins.top)
            .style('fill', d => {
                return d;
            });

        const legendScale = d3lib.scaleLinear()
            .range([0, pal.length - 1])
            .domain([min, max]);
        chart.append('g')
            .attr('transform', 'translate(0,' + (height + 44) + ')')
            .call(d3lib.axisBottom(legendScale)
                .ticks(8)
                .tickFormat(d3lib.format('.6'))
                .tickSize(6));
    }

    render() {
        const options = [
            'globalHorizIrrWm2', 'diffuseHorizIrrWm2', 'directNormalIrrWm2',
            'clearskiDhiUnits', 'ambientTempC', 'dewPointTempC', 'relHumidityPercent',
            'pressureMbar', 'windSpeedMs', 'albedo',
        ];

        return (
            <div className='container'>
                <Dropdown options={options}
                          onChange={this.onChangedSelection}
                          value={this.selectedOption} placeholder="Select an option"/>
                <svg className='chart'/>
                <div className="tooltip">Tooltip</div>
            </div>
        );
    }
}
