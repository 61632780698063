import React, {useState} from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import {createAuthTerraData} from 'terra-api-ts/auth-service.twirp';
import { terra } from "terra-api-ts/service.pb";
import { MapContainer } from "./components/MapContainer";
import { Heatmap } from './components/heatmap';
import { InfoContainer } from './components/infoContainer';
import './App.css';
import 'react-dropdown/style.css';

let baseurl = window.location.origin
let customUrl = false
const urlParams = new URLSearchParams(window.location.search)
if (urlParams.get("address") !== null) {
    baseurl = urlParams.get("address")!
    customUrl = true
    // http://localhost:8010/proxy
    console.log("Using overridden request URL: " + baseurl)
}

let td = createAuthTerraData(baseurl)
let lat: number
let lng: number

export default function App() {
    const [selectedPoint, setSelectedPoint] = useState(terra.GridPoint.create({}))

    function onPointChanged(point: terra.IGridPoint) {
        setSelectedPoint(point as terra.GridPoint)
        let addr = ""
        if (customUrl) {
            addr = `&address=${baseurl}`
        }
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({}, "Point",
            `?lat=${point.position?.lat}&lng=${point.position?.lng}&pointId=${point.id?.value}${addr}`)
    }

    // Check the URL properties
    if (urlParams.get("lat")) {
        lat = parseFloat(urlParams.get("lat") as string)
    }
    if (urlParams.get("lng")) {
        lng = parseFloat(urlParams.get("lng") as string)
    }

    let pointId = urlParams.get("pointId")
    // If point ID is not null, retrieve it and set it!
    if (pointId) {
        td.getPoint(terra.GridPointId.create({value: pointId}),
            function (error: (Error | null), response?: terra.GridPoint) {
            if (response) {
                onPointChanged(response)
            }
        })
    }

    return (
        <div className='app-root'>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY ?? ''}>
                <div className='sidebar'>
                    <label>
                        Terra Token:
                        <input type="text" name="terra_token" onChange={handleTokenChange} />
                    </label>
                    <InfoContainer selectedPoint={selectedPoint} />
                    <Heatmap selectedPoint={selectedPoint} getTd={() => td}/>
                </div>
                <MapContainer getTd={() => td} lat={lat} lng={lng}
                            onPointChanged={onPointChanged}/>
            </APIProvider>
        </div>
    )
}

function handleTokenChange(event: { target: { value: string | undefined; }; preventDefault: () => void; }) {
    td = createAuthTerraData(baseurl, event.target.value)
    event.preventDefault();
}
