import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const ApiDocs: React.FC = () => {
    return (
        <div>
            <SwaggerUI url="./apischema.json" />
        </div>
    );
};

export default ApiDocs;