import {terra} from "terra-api-ts/service.pb";
import React from "react";
import { Marker, Map } from '@vis.gl/react-google-maps';
import { MapCameraChangedEvent } from "@vis.gl/react-google-maps/dist/components/map/use-map-events";
import AddressLine from "./AddressLine";

interface MapState {
    lat: number
    lng: number
    radius: number
    points: Array<terra.IGridPoint>;
}

type pointIdHandler = (point: terra.IGridPoint) => any
type tdGetter = () => terra.TerraData

interface MapContainerProps {
    onPointChanged: pointIdHandler
    getTd: tdGetter
    lat?: number
    lng?: number
}

export class MapContainer extends React.Component<MapContainerProps, any> {
    state: MapState
    map?: google.maps.Map
    pendingUpdate: any
    onPointChanged: pointIdHandler
    markerCache: {[key: string]: any} = {}

    constructor(props: MapContainerProps) {
        super(props);
        this.handleAddressChange = this.handleAddressChange.bind(this)
        this.onPointChanged = props.onPointChanged

        this.pendingUpdate = 0

        let lat = 47.444;
        let lng = -122.176;
        if (props.lat) {
            lat = props.lat
        }
        if (props.lng) {
            lng = props.lng
        }
        this.state = {
            lat: lat,
            lng: lng,
            radius: 10000,
            points: []
        }
    }

    handleAddressChange(event: any) {
        const pos = {lat: event.position.lat(), lng: event.position.lng()}
        this.setState({lat: pos.lat, lng: pos.lng})
        this.forceUpdate()
        this.doUpdateWithDebounce(pos.lat, pos.lng, this.state.radius)
    }

    onMapChanged = (event?: MapCameraChangedEvent) => {
        const bounds = new google.maps.LatLngBounds(event!.detail.bounds)
        const distMeters = this.haversineDistance(bounds!.getNorthEast(), bounds!.getSouthWest())
        const lat = event!.detail!.center!.lat
        const lng = event!.detail!.center!.lng
        const radius = distMeters/2
        this.doUpdateWithDebounce(lat, lng, radius)
        this.setState({lat: lat, lng: lng, radius: radius})
    }

    doUpdateWithDebounce(lat: number, lng: number, radius: number) {
        if (this.pendingUpdate !== 0) {
            clearTimeout(this.pendingUpdate)
        }
        this.pendingUpdate = setTimeout(() => {
            this.pendingUpdate = 0
            this.fetchPoints(lat, lng, radius)
        }, 300)
    }

    haversineDistance(mk1: google.maps.LatLng, mk2: google.maps.LatLng): number {
        const R = 6378000; // Radius of the Earth in meters
        const rlat1 = mk1.lat() * (Math.PI / 180); // Convert degrees to radians
        const rlat2 = mk2.lat() * (Math.PI / 180); // Convert degrees to radians
        const difflat = rlat2 - rlat1; // Radian difference (latitudes)
        const difflon = (mk2.lng() - mk1.lng()) * (Math.PI / 180); // Radian difference (longitudes)

        return 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) +
            Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    }

    fetchPoints(lat: number, lng: number, radius: number) {
        const stateSetter = this.setState.bind(this)
        const td = this.props.getTd()

        if (radius > 100000) {
            stateSetter({points: new Array<terra.IGridPoint>()})
            return
        }

        const result = new Array<terra.IGridPoint>()
        const request = terra.QueryAreaPointsRequest.create({
            rowCount: 1000,
            bounds: terra.GeoBounds.create({
                center: terra.GeoPoint.create({lat: lat, lng: lng}),
                radiusMeters: radius
            }),
        });

        const processor = function (error: (Error | null), response?: terra.GridPointList) {
            if (error != null || !response) {
                console.log("Error" + error?.message)
                return
            }
            result.push(...response.list)
            if (response.paginationToken !== "") {
                request.paginationToken = response.paginationToken
                td.queryGridPoints(request, processor)
            } else {
                stateSetter({points: result})
            }
        }
        td.queryGridPoints(request, processor)
    }

    shouldComponentUpdate(nextProps: Readonly<any>,
                          nextState: Readonly<MapState>, nextContext: any): boolean {
        if (this.state.points.length !== nextState.points.length) {
            return true
        }
        const idx = this.state.points.findIndex((val, index) => {
            return nextState.points[index].id?.value !== val.id?.value
        })
        return idx !== -1
    }

    displayMarkers = () => {
        let newCache: {[key: string]: any} = {}
        let markers = this.state.points.map((store, index) => {
            const pos = store!.position
            const point = store!
            let obj = this.markerCache[point.id!.value!]
            if (obj === undefined) {
                obj = <Marker key={point.id!.value!} icon='wind-gauge-16.png' position={{
                    lat: pos!.lat as number,
                    lng: pos!.lng as number
                }} onClick={() => this.onPointChanged(point)}/>
            }
            newCache[point.id!.value!] = obj
            return obj
        });
        this.markerCache = newCache
        return markers
    }

    render() {

        const {lat, lng} = this.state;
        return (
            <div className="MapBox">
                <AddressLine
                    clickHandler={this.handleAddressChange} {...this.props}  />
                <Map
                    style={{position: 'inherit',
                        width: '100%', height: '100%'}}
                    zoom={13}
                    onBoundsChanged={this.onMapChanged}
                    center={{lat: lat, lng: lng}}
                >
                    {this.displayMarkers()}
                </Map>
            </div>
        );
    }
}

export default MapContainer;
