
const palette = ['#00429d', '#05439e', '#0a459e', '#0f469f', '#1248a0', '#1649a0',
    '#194ba1', '#1b4ca2', '#1e4ea2', '#204fa3', '#2350a4', '#2552a5', '#2753a5', '#2955a6',
    '#2b56a7', '#2d58a7', '#2e59a8', '#305ba9', '#325ca9', '#345eaa', '#355fab', '#3761ab',
    '#3862ac', '#3a64ad', '#3c65ad', '#3d67ae', '#3f68af', '#406aaf', '#426bb0', '#436db1',
    '#456eb1', '#4670b2', '#4771b3', '#4973b3', '#4a75b4', '#4c76b5', '#4d78b5', '#4f79b6',
    '#507bb6', '#517cb7', '#537eb8', '#547fb8', '#5581b9', '#5782ba', '#5884ba', '#5a85bb',
    '#5b87bc', '#5c89bc', '#5e8abd', '#5f8cbd', '#608dbe', '#628fbf', '#6390bf', '#6492c0',
    '#6694c1', '#6795c1', '#6997c2', '#6a98c2', '#6b9ac3', '#6d9bc4', '#6e9dc4', '#6f9fc5',
    '#71a0c5', '#72a2c6', '#74a3c7', '#75a5c7', '#76a6c8', '#78a8c8', '#79aac9', '#7babca',
    '#7cadca', '#7eaecb', '#7fb0cb', '#81b2cc', '#82b3cc', '#84b5cd', '#85b6ce', '#87b8ce',
    '#88b9cf', '#8abbcf', '#8bbdd0', '#8dbed0', '#8ec0d1', '#90c1d1', '#91c3d2', '#93c5d3',
    '#95c6d3', '#96c8d4', '#98c9d4', '#9acbd5', '#9bccd5', '#9dced6', '#9fd0d6', '#a1d1d7',
    '#a2d3d7', '#a4d4d8', '#a6d6d8', '#a8d7d9', '#aad9d9', '#acdbda', '#aedcda', '#afdedb',
    '#b1dfdb', '#b3e1db', '#b6e2dc', '#b8e4dc', '#bae5dd', '#bce7dd', '#bee8dd', '#c0eade',
    '#c3ebde', '#c5eddf', '#c8eedf', '#caf0df', '#cdf1e0', '#cff2e0', '#d2f4e0', '#d5f5e0',
    '#d8f6e1', '#dbf8e1', '#def9e1', '#e2fae1', '#e5fbe1', '#e9fce1', '#eefde1', '#f2fee1',
    '#f8ffe1', '#ffffe0', '#fffdde', '#fffbdd', '#fff9db', '#fff7da', '#fff5d8', '#fff3d7',
    '#fff1d5', '#ffefd4', '#ffedd2', '#ffebd0', '#ffe8cf', '#ffe6cd', '#ffe4cc', '#ffe2ca',
    '#ffe0c9', '#ffdec7', '#ffdcc6', '#ffdac4', '#ffd8c3', '#ffd6c1', '#ffd4bf', '#ffd1be',
    '#ffcfbc', '#ffcdbb', '#ffcbb9', '#ffc9b8', '#ffc7b6', '#ffc5b5', '#ffc3b3', '#ffc0b1',
    '#ffbeb0', '#ffbcae', '#ffbaad', '#ffb8ab', '#ffb5aa', '#ffb3a8', '#ffb1a6', '#ffafa5',
    '#ffaca3', '#ffaaa2', '#ffa8a0', '#ffa69e', '#ffa39d', '#ffa19b', '#ff9f9a', '#ff9c98',
    '#ff9a96', '#ff9895', '#fe9693', '#fd9392', '#fd9191', '#fc8f8f', '#fc8d8e', '#fb8b8c',
    '#fa898b', '#fa878a', '#f98588', '#f88387', '#f78185', '#f77f84', '#f67d83', '#f57a81',
    '#f47880', '#f3767f', '#f2747d', '#f2727c', '#f1707b', '#f06e79', '#ef6c78', '#ee6a77',
    '#ed6876', '#ec6674', '#eb6473', '#ea6272', '#e96070', '#e85e6f', '#e75c6e', '#e55a6d',
    '#e4586c', '#e3556a', '#e25369', '#e15168', '#e04f67', '#de4d65', '#dd4b64', '#dc4963',
    '#da4762', '#d94561', '#d84360', '#d6415e', '#d53f5d', '#d43d5c', '#d23b5b', '#d1395a',
    '#cf3759', '#ce3558', '#cc3357', '#cb3156', '#c92f54', '#c82d53', '#c62b52', '#c42951',
    '#c32750', '#c1254f', '#bf234e', '#be214d', '#bc1f4c', '#ba1d4b', '#b81b4a', '#b61949',
    '#b51748', '#b31447', '#b11246', '#af1045', '#ad0e44', '#ab0c43', '#a90a43', '#a70842',
    '#a50641', '#a30540', '#a1033f', '#9e023e', '#9c023d', '#9a013c', '#98003c', '#95003b',
    '#93003a']

export function colors() {
    return palette
}
